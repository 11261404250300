<template>
  <div class="mx-2">
    <v-card
      v-show="settingsExpanded"
      id="expandCard"
      class="mt-2 mb-2 px-2 py-1"
    >
      <v-row no-gutters style="background-color: #FFFFFF">
        <!--EO Product - symbols explanation -->
        <v-col cols="3" class="d-flex flex-column justify-space-around  ">
          <v-row class="pl-2" no-gutters>{{
            $t("polygonStatistics.legend&Visualisation.Section1title")
          }}</v-row>
          <v-row no-gutters class="text-left pl-2">
            <v-col cols="12">
              <v-icon class="mr-4" :size="7" color="#3584BB"
                >mdi-circle
              </v-icon>
              <span class="text-caption">{{
                $t("polygonStatistics.legend&Visualisation.dataPointNoThr")
              }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters class="text-left pl-2">
            <v-col cols="12">
              <v-icon class="mr-4 mdi-rotate-90" :size="10" color="#0000ff"
                >mdi-color-helper
              </v-icon>

              <span class="text-caption">{{
                $t("polygonStatistics.legend&Visualisation.selectedDate")
              }}</span>
            </v-col>
          </v-row>
        </v-col>

        <!--Thresholds - symbols explanation -->
        <v-col
          cols="4"
          class="d-flex flex-column justify-space-around  "
          :style="{ opacity: this.showThresholds ? 1 : 0.1 }"
        >
          <v-row no-gutters>{{
            $t("polygonStatistics.legend&Visualisation.Section2title")
          }}</v-row>
          <v-row no-gutters class="text-left">
            <v-col cols="6">
              <v-icon
                class="mr-4"
                :size="9"
                :color="this.showThresholds ? 'green' : 'dark-grey'"
                >mdi-circle
              </v-icon>
              <span class="text-caption">{{
                $t("polygonStatistics.legend&Visualisation.normalDataStatus")
              }}</span>
            </v-col>
            <v-col cols="6"> </v-col>
          </v-row>
          <v-row no-gutters class="text-left">
            <v-col cols="6">
              <v-icon
                class="mr-4"
                :size="9"
                :color="this.showThresholds ? 'orange' : 'dark-grey'"
                >mdi-circle
              </v-icon>
              <span class="text-caption">{{
                $t("polygonStatistics.legend&Visualisation.warningDataStatus")
              }}</span>
            </v-col>
            <v-col cols="6">
              <v-icon
                class="mr-1"
                :size="9"
                :color="this.showThresholds ? 'orange' : 'dark-grey'"
                >mdi-color-helper
              </v-icon>
              <v-icon
                class="mr-1"
                :size="9"
                :color="this.showThresholds ? 'orange' : 'dark-grey'"
                >mdi-color-helper
              </v-icon>
              <v-icon
                class="mr-4"
                :size="9"
                :color="this.showThresholds ? 'orange' : 'dark-grey'"
                >mdi-color-helper
              </v-icon>
              <span class="text-caption">{{
                $t("polygonStatistics.legend&Visualisation.warningLine")
              }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters class="text-left">
            <v-col cols="6">
              <v-icon
                class="mr-4"
                :size="9"
                :color="this.showThresholds ? 'red' : 'dark-grey'"
                >mdi-circle
              </v-icon>
              <span class="text-caption">{{
                $t("polygonStatistics.legend&Visualisation.alertDataStatus")
              }}</span>
            </v-col>
            <v-col cols="6">
              <v-icon
                class="mr-0 pa-0"
                :size="9"
                :color="this.showThresholds ? 'red' : 'dark-grey'"
                >mdi-color-helper
              </v-icon>
              <v-icon
                class="mr-0 pa-0"
                :size="9"
                :color="this.showThresholds ? 'red' : 'dark-grey'"
                >mdi-color-helper
              </v-icon>
              <v-icon
                class="mr-0 pa-0"
                :size="9"
                :color="this.showThresholds ? 'red' : 'dark-grey'"
                >mdi-color-helper
              </v-icon>
              <v-icon
                class="mr-4 pa-0"
                :size="9"
                :color="this.showThresholds ? 'red' : 'dark-grey'"
                >mdi-color-helper
              </v-icon>
              <span class="text-caption">{{
                $t("polygonStatistics.legend&Visualisation.alertLine")
              }}</span>
            </v-col>
          </v-row>
        </v-col>

        <!--In situ data - symbols explanation -->

        <v-col
          cols="2"
          class="d-flex flex-column justify-space-around  "
          :style="{ opacity: this.showInSituData ? 1 : 0.1 }"
        >
          <v-row no-gutters>{{
            $t("polygonStatistics.legend&Visualisation.Section3title")
          }}</v-row>

          <v-row no-gutters class="text-left">
            <v-col cols="12">
              <v-icon
                class="mr-4"
                :size="9"
                :color="this.showInSituData ? '#173466' : 'dark-grey'"
                >mdi-rhombus
              </v-icon>
              <span class="text-caption">{{
                $t("polygonStatistics.legend&Visualisation.Section3data")
              }}</span>
            </v-col>
          </v-row>
        </v-col>

        <!--Polygon statistics - symbols explanation -->

        <v-col
          cols="3"
          class="d-flex flex-column justify-space-around"
          :style="{ opacity: this.showPolgonStatistics ? 1 : 0.1 }"
        >
          <v-row no-gutters>{{
            $t("polygonStatistics.legend&Visualisation.Section4title")
          }}</v-row>

          <v-row no-gutters class="text-left">
            <v-col cols="12">
              <v-row no-gutters class="pr-2">
                <v-col
                  class="text-caption d-flex justify-left align-center mr-4"
                >
                  <span
                    v-if="changeLegend"
                    class="text-caption text-capitalize"
                    v-html="formatSelectedItems2"
                  ></span>
                  <span
                    v-else
                    class="text-caption text-capitalize"
                    v-html="formatSelectedItems2"
                  ></span>
                </v-col>

                <v-col class="d-flex justify-left align-center">
                  <v-icon
                    :size="5"
                    :color="this.showPolgonStatistics ? '#02A99F' : 'dark-grey'"
                    >mdi-circle
                  </v-icon></v-col
                >

                <v-col class="d-flex justify-left align-center">
                  <v-icon
                    :size="7"
                    :color="this.showPolgonStatistics ? '#02A99F' : 'dark-grey'"
                    >mdi-circle
                  </v-icon></v-col
                >

                <v-col class="d-flex justify-left align-center">
                  <v-icon
                    :size="9"
                    :color="this.showPolgonStatistics ? '#02A99F' : 'dark-grey'"
                    >mdi-circle
                  </v-icon></v-col
                >

                <v-col class="d-flex justify-left align-center">
                  <v-icon
                    :size="11"
                    :color="this.showPolgonStatistics ? '#02A99F' : 'dark-grey'"
                    >mdi-circle
                  </v-icon></v-col
                >

                <v-col class="d-flex justify-left align-center">
                  <v-icon
                    :size="13"
                    :color="this.showPolgonStatistics ? '#02A99F' : 'dark-grey'"
                    >mdi-circle
                  </v-icon></v-col
                >
              </v-row>
              <!--Percentile-->
              <v-row no-gutters class="pr-2">
                <v-col cols="auto">
                  <v-icon
                    class="align_vertical_bottom mr-8"
                    :color="
                      this.showPolgonStatistics ? 'light-blue' : 'dark-grey'
                    "
                    >mdi-chart-line-variant
                  </v-icon></v-col
                >
                <v-col>
                  <span
                    v-if="changeLegend"
                    class="text-caption"
                    v-html="formatSelectedItems"
                  ></span>
                  <span
                    v-else
                    class="text-caption"
                    v-html="formatSelectedItems"
                  ></span>
                </v-col>
              </v-row>
              <!--Interquartile range-->
              <v-row no-gutters class="pr-2">
                <v-col cols="auto">
                  <v-icon
                    class="align_vertical_bottom mr-8"
                    :color="this.showPolgonStatistics ? '#d5ebf2' : 'dark-grey'"
                    >mdi-chart-areaspline-variant
                  </v-icon></v-col
                >
                <v-col>
                  <span class="text-caption">{{
                    $t("polygonStatistics.legend&Visualisation.range")
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      class="mt-2 mb-2 px-2 py-1"
      v-show="showPolgonStatistics"
      id="customizationCard"
    >
      <!--Customization header -->
      <v-row
        no-gutters
        class="text-caption pt-1 pb-2 pl-2"
        style="background-color: #FFFFFF"
      >
        <span :style="{ opacity: this.showPolgonStatistics ? 1 : 0.1 }">
          {{ $t("polygonStatistics.legend&Visualisation.customizationHeader") }}
        </span>
      </v-row>

      <!--Polygon statistics - fetch customization-->
      <!--v-chips headlines-->
      <v-row style="background-color: #FFFFFF" no-gutters>
        <v-col
          class="text-left d-flex align-center pl-2"
          cols="3"
          :style="{ opacity: this.showPolgonStatistics ? 1 : 0.1 }"
          >{{ $t("polygonStatistics.legend&Visualisation.headline1") }}</v-col
        >
        <v-col
          class="text-left d-flex align-center"
          cols="4"
          :style="{ opacity: this.showPolgonStatistics ? 1 : 0.1 }"
          >{{ $t("polygonStatistics.legend&Visualisation.headline2") }}</v-col
        >
        <v-col
          class="text-left d-flex align-center"
          cols="auto"
          :style="{ opacity: this.showPolgonStatistics ? 1 : 0.1 }"
          >{{ $t("polygonStatistics.legend&Visualisation.headline3") }}</v-col
        >
      </v-row>
      <!--v-chips for fetch setting-->
      <v-row style="background-color: #FFFFFF" no-gutters class="pb-1">
        <v-col
          class="text-left d-flex align-center pl-2"
          cols="3"
          :style="{ opacity: this.showPolgonStatistics ? 1 : 0.1 }"
        >
          <v-chip
            class="pa-2 mr-1"
            elevated
            :text-color="selectedItems[0] === item ? '#039BE5' : 'black'"
            :outlined="selectedItems[0] === item"
            v-for="(item, index) in itemGroups[0]"
            :key="index + '-' + item"
            @click="selectItem(0, item)"
            clickable
          >
            {{
              item === "median"
                ? $t("polygonStatistics.legend&Visualisation.median")
                : $t("polygonStatistics.legend&Visualisation.mean")
            }}
            <template v-if="selectedItems[0] === item">
              <v-icon right>
                mdi-check
              </v-icon>
            </template>
          </v-chip>
        </v-col>
        <v-col
          class="text-left d-flex align-center"
          cols="4"
          :style="{ opacity: this.showPolgonStatistics ? 1 : 0.1 }"
        >
          <v-chip
            class="pa-2 mr-1"
            elevated
            :text-color="selectedItems[1] === item ? '#039BE5' : 'black'"
            :outlined="selectedItems[1] === item"
            v-for="(item, index) in itemGroups[1]"
            :key="index + '-' + item"
            @click="selectItem(1, item)"
            clickable
          >
            {{
              item === "valid_percent"
                ? $t("polygonStatistics.legend&Visualisation.validPixels") +
                  " [%]"
                : $t("polygonStatistics.legend&Visualisation.stdFull")
            }}

            <template v-if="selectedItems[1] === item">
              <v-icon right>
                mdi-check
              </v-icon>
            </template>
          </v-chip>
        </v-col>
        <v-col
          class="text-left d-flex align-center"
          :style="{ opacity: this.showPolgonStatistics ? 1 : 0.1 }"
        >
          <v-chip
            class="pa-2 mr-1"
            elevated
            :text-color="
              isSelected(selectedItems[2], item) ? '#039BE5' : 'black'
            "
            :outlined="isSelected(selectedItems[2], item)"
            v-for="(item, index) in itemGroups[2]"
            :key="index + '-' + item"
            @click="selectItem(2, item)"
            clickable
          >
            <span v-html="getPercentileLabel(item)"> </span>
            <template v-if="isSelected(selectedItems[2], item)">
              <v-icon right>
                mdi-check
              </v-icon>
            </template>
          </v-chip>
        </v-col>
        <!--Apply button-->
        <v-col cols="auto" class="text-right d-flex align-center pr-1">
          <v-btn
            rounded
            color="#40ADD6"
            :disabled="!isSelectionChanged"
            @click="apply"
          >
            <span style="color: white" class="text-capitalize">
              {{ $t("polygonStatistics.legend&Visualisation.applyBtn") }}
              <v-icon v-if="isLoading" spin>mdi-loading mdi-spin</v-icon>
            </span></v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "PlotKeyAndConfiguration",
  data: () => ({
    itemGroups: [
      ["median", "mean"],
      ["valid_percent", "std"],
      [
        ["p25", "p75"],
        ["p10", "p90"]
      ]
    ],
    localInitItem: null,
    appliedItems: ["median", "valid_percent", ["p25", "p75"]],
    changeLegend: false
  }),
  props: {
    showThresholds: Boolean,
    showInSituData: Boolean,
    showPolgonStatistics: Boolean,
    settingsExpanded: Boolean,
    initialSelectedItems: Array,
    selectedItems: Array,
    isLoading: Boolean
  },
  computed: {
    isSelectionChanged() {
      return !this.areSelectionsEqual(
        this.selectedItems,
        this.initialSelectedItems
      );
    },
    formatSelectedItems() {
      if (
        this.appliedItems[2][0] === "p25" &&
        this.appliedItems[2][1] === "p75"
      ) {
        return this.$t("polygonStatistics.legend&Visualisation.25-75");
      } else if (
        this.appliedItems[2][0] === "p10" &&
        this.appliedItems[2][1] === "p90"
      ) {
        return this.$t("polygonStatistics.legend&Visualisation.10-90");
      }
      return "";
    },
    formatSelectedItems2() {
      let firstItem = this.appliedItems[1];

      if (firstItem === "valid_percent") {
        firstItem =
          this.$t("polygonStatistics.legend&Visualisation.validPixels") +
          " [%]";
      } else {
        firstItem = this.appliedItems[1];
        if (firstItem === "std") {
          firstItem = this.$t("polygonStatistics.legend&Visualisation.std");
        }
      }
      return firstItem;
    }
  },
  methods: {
    selectItem(groupIndex, item) {
      this.$set(this.selectedItems, groupIndex, item);
    },
    isSelected(selectedItem, item) {
      if (Array.isArray(selectedItem) && Array.isArray(item)) {
        return JSON.stringify(selectedItem) === JSON.stringify(item);
      }
      return selectedItem === item;
    },
    areSelectionsEqual(a, b) {
      return JSON.stringify(a) === JSON.stringify(b);
    },
    apply() {
      this.localInitItem = JSON.parse(JSON.stringify(this.selectedItems));
      this.appliedItems = JSON.parse(JSON.stringify(this.selectedItems));

      this.changeLegend = true;
      this.$emit("update-initialSelectedItems", this.localInitItem);
      this.$emit("fetchPolygonStats");
    },
    getPercentileLabel(item) {
      if (item[0] === "p25" && item[1] === "p75") {
        return this.$t("polygonStatistics.legend&Visualisation.25-75");
      } else if (item[0] === "p10" && item[1] === "p90") {
        return this.$t("polygonStatistics.legend&Visualisation.10-90");
      }
    },
    calculateHeights() {
      this.$nextTick(() => {
        const expandCard = document.getElementById("expandCard");
        const customizationCard = document.getElementById("customizationCard");

        let totalHeight = 0;

        if (expandCard) {
          totalHeight += expandCard.clientHeight;
        }

        if (customizationCard) {
          totalHeight += customizationCard.clientHeight;
        }
        this.$emit("totalHeightCalculated", totalHeight);
      });
    }
  },
  watch: {
    initialSelectedItems() {
      this.appliedItems = this.initialSelectedItems;
    },
    settingsExpanded() {
      this.calculateHeights();
    },
    showPolgonStatistics() {
      this.calculateHeights();
    }
  },
  created() {
    this.appliedItems = this.initialSelectedItems;
  }
};
</script>
<style scoped></style>
