<template>
  <div id="parent_container" style="display: flex; height: 100%;">
    <div
      class="mt-8"
      ref="plot"
      :id="container"
      :style="{
        top: 0,
        bottom: 0,
        width: '100%',
        height: '83%',
        flex: 1
      }"
    ></div>
  </div>
</template>

<script>
import plotly from "plotly.js-dist";
import { mapState } from "vuex";
export default {
  name: "DataBoxPlot",
  props: {
    container: String,
    thresholds: Boolean,
    jsonFetch: Object,
    inSituData: Object,
    inSituDataContainer: String,
    selectedStation: String,
    showInSituData: Boolean
  },
  data: () => ({
    plotData: [],
    plotLayout: [],
    plotJsonFetch: null
  }),
  computed: {
    chart() {
      return {
        traces: this.dataCache,
        layout: {
          title: "Data Box Plot"
        }
      };
    },
    ...mapState("inSitu", ["singleColorInSituData"])
  },
  //https://plotly.com/javascript/plotlyjs-function-reference/
  methods: {
    createPlot() {
      const { data, layout } = this.getPLotyFormat(
        "boxplot",
        this.plotJsonFetch
      );
      this.plotData = { y: data[0].y, type: "box", name: this.selectedStation };
      this.plotLayout = layout;
      var config = {
        modeBarButtonsToRemove: [
          "toggleHover",
          "sendDataToCloud",
          "toggleSpikelines",
          "resetScale2d",
          "pan2d",
          "zoom2d",
          "select2d"
        ],
        responsive: true
      };
      plotly.newPlot(this.container, [this.plotData], layout, config);
    },

    updatePlot(data, layout) {
      plotly.react(this.container, [data], layout);
    },
    getPLotyFormat(param, plotJsonFetch) {
      let plotData = {
        y: [],
        warning: [],
        alert: []
      };
      plotJsonFetch["statistics"].map(item => {
        plotData.y.push(item.value);
        plotData.warning.push(
          plotJsonFetch["thresholds"]?.["warning"]?.["value"]
        );
        plotData.alert.push(plotJsonFetch["thresholds"]?.["alert"]?.["value"]);
        return;
      });
      let plotlyFormat = {
        data: [
          {
            type: "scatter",
            mode: "markers+text",
            connectgaps: true,
            automargin: true,
            marker: {
              size: plotData.size,
              color: plotData.color,
              symbol: plotData.circle,
              opacity: 0.9
            },
            x: plotData.x,
            y: plotData.y,
            name: this.$t("dashboardDetails.value"),
            customdata: plotData.customdata
          },
          {
            type: "scatter",
            mode: "lines",
            x: plotData.x,
            y: plotData.warning,
            name: this.$t("dashboardDetails.warningLimit"),
            line: {
              dash: "dashdot",
              color: "rgba(254,204,92,1)",
              width: 2.0
            }
          },
          {
            type: "scatter",
            mode: "lines",
            x: plotData.x,
            y: plotData.alert,
            name: this.$t("dashboardDetails.alertLimit"),
            line: {
              color: "rgba(240,59,32,1)",
              width: 1.5
            }
          }
        ],
        layout: {
          yaxis: {
            side: "bottom",
            autorange: true,
            range: [0, 8],
            type: "linear",
            title: {
              standoff: 10
            },
            titlefont: {
              size: 12,
              color: "rgba(53,76,120,1)"
            },
            rangemode: "tozero"
          },
          margin: { autoexpand: false, l: 65, r: 10, b: 50, t: 30, pad: 1 },
          showlegend: false
        }
      };
      return plotlyFormat;
    },
    createInSituBoxPlot(values) {
      this.inSituBoxPlotData = {
        y: values,
        type: "box",
        name: this.$t("inSitu.dashboardInSituPlotTitle"),
        marker: {
          color: this.singleColorInSituData,
          symbol: "diamond"
        },
        customdata: ["sourceInSituData"]
      };
      plotly.addTraces(this.container, [this.inSituBoxPlotData]);
    },
    removeTraceByCustomData(customDataValue) {
      const plotContainer = this.$refs.plot;
      let traceIndicesToRemove = [];
      if (plotContainer && plotContainer.data) {
        plotContainer.data.forEach((trace, index) => {
          if (trace.customdata && trace.customdata.includes(customDataValue)) {
            traceIndicesToRemove.push(index);

            plotly.deleteTraces(this.container, index);
            plotly.relayout(this.container, {
              autosize: true,
              responsive: true
            });
          }
        });
      }
    },
    checkStateOfInSituDataVisibility() {
      const plotContainer = this.$refs.plot;
      if (plotContainer && this.inSituData) {
        if (this.showInSituData) {
          this.$nextTick(() => {
            plotly.relayout(this.container, {});

            this.createInSituBoxPlot(this.inSituData.values);
            plotly.relayout(this.container, {
              autosize: true,
              responsive: true
            });
          });
        } else {
          this.removeTraceByCustomData("sourceInSituData");
        }
      }
    }
  },
  watch: {
    plotJsonFetch(value) {
      if (value != null) {
        let events = { statistics: false };
        let key = "statistics";
        if (Object.prototype.hasOwnProperty.call(events, key)) {
          this.createPlot();
        }
      }
    },
    showInSituData(value) {
      if (value) {
        this.checkStateOfInSituDataVisibility();
      } else {
        this.checkStateOfInSituDataVisibility();
      }
    }
  },
  created() {
    this.plotJsonFetch = this.jsonFetch;
  },
  mounted() {
    this.checkStateOfInSituDataVisibility();
  }
};
</script>

<style scoped></style>
