<template>
  <div class="child">
    <div
      :id="container"
      :style="{
        top: 0,
        bottom: 0,
        width: '100%',
        height: '100%'
      }"
      class="child"
    >
      <v-select
        class="layer-selector"
        v-if="itemsLayer"
        v-model="selectedLayer"
        :items="itemsLayer"
        @input="changeLayer"
        name="title"
        item-text="title"
        item-value="layerId"
        label="Select Layer"
        dense
        single-line
        flat
        solo
      ></v-select>
      <v-spacer />
      <v-btn
        v-if="downloadPossible"
        color="white"
        fab
        x-small
        dense
        href="#"
        @click="downloadRaster"
        class="download-button"
        title="download Rasterdata"
      >
        <v-icon class="pa-0" color="black">mdi-download</v-icon>
      </v-btn>
      <div class="download-button" v-else></div>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import { mapState, mapActions, mapGetters } from "vuex";
import MapboxConfig from "@/core/plugins/mapbox";
import bbox from "@turf/bbox";
import popUpMixin from "@/core/mixins/popUp.mixin";

export default {
  name: "Map",
  props: {
    container: String,
    joinView: String,
    itemsLayer: Array,
    selectedLayerDefault: String,
    sensor: String,
    timeLayer: String,
    virtualStation: Object,
    clickedProductLayer: String,
    availableData: Array
  },
  mixins: [popUpMixin],
  data: () => ({
    map: null,
    height: null,
    selectedLayer: null,
    downloadPossible: false,
    indexLayerDummys: [
      {
        id: "z-index-1",
        type: "symbol",
        source: "z-index-1"
      },
      {
        id: "z-index-2",
        type: "symbol",
        source: "z-index-2"
      }
    ],
    sceneData: null
  }),
  computed: {
    ...mapState("dashboard", ["center"]),
    ...mapGetters("dashboard", ["getCenter"]),
    ...mapState("management", ["accessToken"])
  },
  methods: {
    ...mapActions("dashboard", ["changeCenter"]),
    ...mapActions("valuePopup", [
      "setSelectedLayerTopMap",
      "setSelectedLayerBottomMap",
      "setTimeLayer"
    ]),
    createMap() {
      const bounds = bbox(this.virtualStation.geometry);
      mapboxgl.accessToken = MapboxConfig.accessToken;
      const map = new mapboxgl.Map({
        container: this.container,
        style: "mapbox://styles/mapbox/streets-v11",
        bounds: bounds
      });

      map.dragRotate.disable();
      map.on("load", () => {
        map.fitBounds(bounds, { padding: 50 });
        this.addIndexLayerDummys();
        this.addRastlessLayer();
        this.addVirtualStationLayer();
      });
      const viewer = () => {
        const { lng, lat } = map.getCenter();
        const zoom = map.getZoom();
        this.changeCenter({
          lat,
          lng,
          container: this.container,
          active: true,
          zoom
        });
      };
      map.on("dragend", () => {
        viewer();
      });
      map.on("zoomend", () => {
        viewer();
      });
      map.on("click", event => {
        this.showValuePopup(
          event,
          map,
          this.selectedLayer,
          this.timeLayer,
          this.itemsLayer,
          this.container
        );
      });
      map.on("touchend", event => {
        this.showValuePopup(
          event,
          map,
          this.selectedLayer,
          this.timeLayer,
          this.itemsLayer,
          this.container
        );
      });
      map.getCanvas().style.cursor = "pointer";
      this.map = map;
    },
    addIndexLayerDummys() {
      // TODO: check if layerDummys loaded before adding vs + raster layer
      const map = this.map;
      this.indexLayerDummys.forEach(layerDummy => {
        if (map.getLayer(layerDummy.id)) {
          map.removeLayer(layerDummy.id);
        }
        if (map.getSource(layerDummy.id)) {
          map.removeSource(layerDummy.id);
        }
        map.addSource(layerDummy.id, {
          type: "geojson",
          data: { type: "FeatureCollection", features: [] }
        });
        map.addLayer(layerDummy);
      });
    },

    addVirtualStationLayer() {
      const map = this.map;

      if (map.getLayer("geojson-source")) {
        map.removeLayer("geojson-source");
      }
      if (map.getSource("geojson-source")) {
        map.removeSource("geojson-source");
      }

      map.addSource("geojson-source", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: this.virtualStation.geometry
        }
      });
      if (this.virtualStation.geometry.type === "Point") {
        map.addLayer(
          {
            id: "geojson-source",
            source: "geojson-source",
            type: "circle",
            layout: {
              visibility: `visible`
            },
            paint: {
              "circle-color": "#ff9800",
              "circle-opacity": 1,
              "circle-radius": 20
            }
          },
          "z-index-2"
        );
      } else {
        map.addLayer(
          {
            id: "geojson-source",
            source: "geojson-source",
            type: "line",
            layout: {
              visibility: `visible`
            },
            paint: {
              "line-color": "#ff9800",
              "line-width": 3
            }
          },
          "z-index-2"
        );
      }
    },
    addRastlessLayer() {
      const map = this.map;
      if (map.getLayer("raster-layer")) {
        map.removeLayer("raster-layer");
      }
      if (map.getSource("raster-source")) {
        map.removeSource("raster-source");
      }

      const domain = process.env.VUE_APP_RASTLESS_URL;
      let urlTiles = "";

      const productData = this.availableData.find(
        data => data.product === this.selectedLayer
      );
      if (productData) {
        const sceneData = productData.availableScenes.find(
          scene => scene.datetime === this.timeLayer
        );

        this.sceneData = sceneData;

        if (sceneData) {
          if (this.selectedLayer === "satellite") {
            urlTiles = `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MapboxConfig.accessToken}`;
          } else {
            urlTiles = `${domain}/layers/${this.selectedLayer}/${this.timeLayer}/tile/{z}/{x}/{y}.png?token=${this.accessToken}`;
          }
          map.addSource(`raster-source`, {
            type: "raster",
            tiles: [urlTiles],
            tileSize: 256
          });
          map.addLayer(
            {
              id: `raster-layer`,
              type: `raster`,
              source: `raster-source`,
              layout: {
                visibility: `visible`
              },
              metadata: `detailsDashboard`
            },
            "z-index-1"
          );
        } else {
          return;
        }
      } else {
        return;
      }
    },
    checkDownloadPossibility() {
      if (!this.sceneData) {
        this.downloadPossible = false;
        return;
      }
      const product = this.getProductNameFromLayerId();
      if (
        (this.sensor == "SKYST" && product == "rgb") |
        (this.sensor == "SUDOV")
      ) {
        this.downloadPossible = false;
      } else {
        this.downloadPossible = true;
      }
    },
    getProductNameFromLayerId() {
      const layerName = this.itemsLayer.filter(
        item => item.layerId === this.selectedLayer
      );
      return layerName[0].product;
    },

    changeLayer(value) {
      const map = this.map;
      this.selectedLayer = value;
      if (this.container === "topMap") {
        this.setSelectedLayerTopMap(value);
      } else if (this.container === "bottomMap") {
        this.setSelectedLayerBottomMap(value);
      }
      const layers = map.getStyle().layers;
      const filtered = layers.filter(
        item =>
          item.metadata === "detailsDashboard" &&
          item.id !== `raster-layer-${value}`
      );
      if (map.getLayer(`raster-layer-${value}`)) {
        filtered.forEach(item => {
          map.setLayoutProperty(item.id, "visibility", "none");
        });
        map.setLayoutProperty(`raster-layer-${value}`, "visibility", "visible");
      } else {
        if (filtered.length !== 0) {
          filtered.forEach(item => {
            map.setLayoutProperty(item.id, "visibility", "none");
          });
        }
        this.addRastlessLayer();
      }
    },

    async downloadRaster() {
      const response = await this.$rastless.get(
        `/layers/${this.selectedLayer}/${this.timeLayer}/download?token=${this.accessToken}`
      );
      response.data.urls.forEach(url => {
        window.open(url);
      });
    }
  },
  async mounted() {
    this.selectedLayer = this.selectedLayerDefault;
    if (this.selectedLayer !== "satellite") {
      this.checkDownloadPossibility();
    }
    this.createMap();
  },
  watch: {
    center(param) {
      const { lat, lng, container, active, zoom } = param;
      if (active && this.joinView === container) {
        this.map.setCenter([lng, lat]);
        this.map.setZoom(zoom);
      }
    },
    timeLayer(newVal) {
      this.$store.dispatch("valuePopup/setTimeLayer", newVal);
      this.checkDownloadPossibility();
      this.addRastlessLayer();
    },
    virtualStation: {
      handler() {
        if (this.virtualStation.geometry && this.map) {
          this.addVirtualStationLayer();
        }
      },
      immediate: true
    },
    clickedProductLayer(newVal) {
      this.selectedLayer = newVal;
      this.changeLayer(this.selectedLayer);
      this.checkDownloadPossibility();
    },
    selectedLayer() {
      this.checkDownloadPossibility();
    },
    sceneData() {
      this.checkDownloadPossibility();
    }
  }
};
</script>

<style scoped>
.child {
  height: 100%;
}

.mapboxgl-canvas {
  left: 0;
}

.layer-selector {
  z-index: 2;
  padding-top: 4px;
  padding-left: 4px;
  position: fixed;
  width: 220px;
}

.v-text-field.v-text-field--solo.v-input--dense >>> .v-input__control {
  /*/ reduce heigth from product drop down in map */
  min-height: 26px;
}

.download-button {
  position: fixed;
  right: 0;
  z-index: 2;
  margin-right: 20px;
  margin-top: 5px;
}
</style>
