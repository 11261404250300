<template>
  <div style="height: 100%">
    <v-row class="pl-5" style="align-items: start" no-gutters>
      <v-col align-self="start" cols="auto">
        <span class="text-h6 font-weight-regular"> {{ titleBase }}</span>
      </v-col>
      <v-col cols="auto" class="pl-0 pt-4">
        <v-tooltip top v-if="editMode">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!markedTimesteps.length"
              color="primary"
              class="pb-1 text-lowercase"
              text
              v-bind="attrs"
              v-on="on"
              @click="saveMarkedTimesteps"
            >
              {{ $t("baseline.deleteSelected") }}
            </v-btn>
          </template>
          <span>{{ $t("baseline.deleteSelectedPoints") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="editMode">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!oldMarkedTimesteps.length"
              :color="oldMarkedTimesteps.length ? 'primary' : ' grey'"
              class="px-1 text-lowercase"
              dark
              x-small
              icon
              text
              v-bind="attrs"
              v-on="on"
              @click="resetAllTimesteps"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("baseline.resetChanges") }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <div
      ref="plot"
      :id="container"
      :style="{
        top: 0,
        bottom: 0,
        width: '100%',
        height: '83%'
      }"
    ></div>
  </div>
</template>

<script>
import plotly from "plotly.js-dist";
import dataManagementMixin from "@/core/mixins/dataManagement.mixin";
import { mapActions, mapState } from "vuex";

export default {
  name: "DataPlot",
  mixins: [dataManagementMixin],
  props: {
    timeLayer: String,
    container: String,
    thresholds: Boolean,
    titleBase: String,
    titleXaxis: String,
    titleYaxis: String,
    jsonFetch: Object,
    productLayer: String,
    editMode: Boolean,
    virtualStation: Object,
    inSituData: Object,
    showInSituData: Boolean,
    polygonStatistics: Object,
    fetchedPolygonStatistics: Object,
    showPolygonStatistics: Boolean,
    unit: String
  },
  data: () => ({
    plotData: [],
    plotLayout: [],
    plotJsonFetch: null,
    markedTimesteps: [],
    addedTimestep: [],
    oldMarkedTimesteps: [],
    inSituDataToNoThresholdPlot: []
  }),
  computed: {
    chart() {
      return {
        traces: this.dataCache,
        layout: {
          title: "Data Plot"
        }
      };
    },
    ...mapState("inSitu", ["singleColorInSituData"]),
    combinedPlotDataNOTHR() {
      let plotData = [];

      if (this.plotData.length) {
        if (this.timeLayer) {
          this.plotData[1].x.forEach((value, index) => {
            this.plotData[1].x[index] = this.timeLayer;
          });
        }
        if (this.plotData) {
          const dataNoThresholds = JSON.parse(JSON.stringify(this.plotData[0]));
          const currentSceneVerticalLine = JSON.parse(
            JSON.stringify(this.plotData[1])
          );
          dataNoThresholds.marker.color = "#3584BB";
          plotData = [dataNoThresholds, currentSceneVerticalLine];
        }
        if (this.showInSituData) {
          plotData.push(...this.createInSituDataPlot());
        }

        if (this.showPolygonStatistics) {
          plotData.push(...this.createPolygonStatisticsPlot());
        }
      }
      return plotData;
    }
  },
  //https://plotly.com/javascript/plotlyjs-function-reference/
  methods: {
    ...mapActions("app", ["showSnackbar"]),

    createPlot() {
      const title = {
        titleXaxis: this.titleXaxis || "",
        titleYaxis: this.titleYaxis || ""
      };
      const { data, layout } = this.getPLotyFormat(title, this.plotJsonFetch);
      this.plotData = data;
      this.plotLayout = layout;
      const productLayer = this.productLayer;
      var config = {
        modeBarButtonsToRemove: [
          "toggleHover",
          "sendDataToCloud",
          "toggleSpikelines",
          "resetScale2d",
          "lasso2d",
          "pan2d",
          "zoom2d",
          "select2d"
        ],
        responsive: true
      };
      plotly.newPlot(this.container, data, layout, config);

      // Get rastless datetime from plot
      this.addDefaultClickEvent(productLayer);
    },
    addDefaultClickEvent(productLayer) {
      const _this = this;
      if (this.$refs.plot) {
        this.$refs.plot.on("plotly_click", function(data) {
          if (data.points && data.points.length > 0) {
            const point = data.points[0];
            if (
              point.data.customdata &&
              point.data.customdata[0] === "sourceInSituData"
            ) {
              return;
            }
            data.points.forEach(point => {
              if (point.customdata) {
                _this.$emit("clickedDatetime", point.customdata);
                _this.$emit("clickedProductLayer", productLayer);
              } else if (point.x) {
                let formattedX = point.x;

                if (!formattedX.includes("T")) {
                  formattedX = formattedX.replace(" ", "T");
                }
                // Add seconds if missing
                if (formattedX.length === 16) {
                  formattedX += ":00";
                }
                _this.$emit("clickedDatetime", formattedX);
                _this.$emit("clickedProductLayer", productLayer);
              }
            });
          }
        });
      }
    },
    updatePlot(data, layout) {
      plotly.react(this.container, data, layout);
    },
    getPLotyFormat(param, plotJsonFetch) {
      let { titleXaxis, titleYaxis } = param;
      let plotData = {
        x: [],
        customdata: [],
        y: [],
        size: [],
        symbol: [],
        datetime: []
      };
      plotJsonFetch["statistics"].map(item => {
        plotData.x.push(item.scene_date);
        plotData.customdata.push(item.scene_date);
        plotData.y.push(item.value);
        plotData.size.push(7);
        plotData.symbol.push("circle");
        plotData.datetime.push(this.timeLayer);
        return;
      });

      let plotlyFormat = {
        data: [
          {
            type: "scatter",
            mode: "markers",
            connectgaps: true,
            automargin: true,
            marker: {
              size: plotData.size,
              color: "#3584BB",
              symbol: plotData.circle,
              opacity: 0.9
            },
            x: plotData.x,
            y: plotData.y,
            name: this.$t("dashboardDetails.value"),
            customdata: plotData.customdata
          },
          {
            type: "scatter",
            mode: "lines",
            x: [plotData.datetime[0], ...plotData.datetime],
            y: [0, ...plotData.y],
            name: this.$t("dashboardDetails.date"),
            line: {
              color: "blue",
              width: 1.5
            }
          }
        ],
        layout: {
          xaxis: {
            type: "date",
            title: titleXaxis,
            titlefont: {
              size: 14,
              color: "rgba(0,0,0,0.8)"
            }
          },
          yaxis: {
            side: "bottom",
            autorange: true,
            range: [0, 8],
            type: "linear",
            title: {
              text: titleYaxis,
              standoff: 10
            },
            titlefont: {
              size: 12,
              color: "rgba(53,76,120,1)"
            },
            rangemode: "tozero"
          },
          margin: { autoexpand: false, l: 65, r: 10, b: 50, t: 30, pad: 1 },
          showlegend: false,
          hovermode: "closest"
        }
      };
      return plotlyFormat;
    },
    addClickEvent() {
      var plotDiv = document.getElementById(this.container);
      const mode = this.editMode;
      let colors = [];
      let addedTimestep = this.addedTimestep;
      plotDiv.on("plotly_click", data => {
        if (mode) {
          data.points.forEach(point => {
            const index = data.points[0].pointIndex;
            addedTimestep.push(point.data.x[index]);
            point.data.x.forEach((x, idx) => {
              if (colors.length < point.data.x.length) {
                colors.push(point.data.marker.color);
              } else {
                colors[idx] = point.data.marker.color[idx];
              }
            });
            colors[index] = colors[index] === "#3584BB" ? "#F6A124" : "#3584BB";

            var update = {
              marker: {
                color: colors,
                line: {
                  color: "#fff",
                  width: 0.8
                },
                size: 7
              }
            };
            plotly.restyle(
              plotDiv,
              update,
              JSON.parse(JSON.stringify(this.plotData[0]))
            );
          });
        }
      });
    },
    manageEditMode() {
      let plotDiv = document.getElementById(this.container);
      plotDiv.removeAllListeners("plotly_click");
      if (!this.editMode) {
        const restore = {
          marker: {
            color: "#3584BB",
            size: 7,
            opacity: 0.9,
            line: {
              color: "white",
              width: 0.8
            }
          }
        };
        plotly.restyle(this.container, restore, 0);
        this.addDefaultClickEvent(this.productLayer);
      } else {
        this.markedTimesteps = [];
        const update = {
          marker: {
            color: "#3584BB",
            size: 7,
            line: {
              color: "white",
              width: 0.8
            }
          }
        };
        plotly.restyle(this.container, update, 0);
        this.addClickEvent();
      }
    },
    async saveMarkedTimesteps() {
      await this.markTimestepsAsDeleted(
        this.$route.params.regionId,
        this.productLayer,
        this.virtualStation.id,
        this.markedTimesteps
      );
      this.$emit("toggleReload");
    },
    async resetAllTimesteps() {
      await this.resetMarkedTimesteps(
        this.productLayer,
        this.virtualStation.id
      );
      this.$emit("toggleReload");
    },
    createInSituDataPlot() {
      if (
        !this.inSituData ||
        !this.inSituData.date_times ||
        !this.inSituData.values
      ) {
        return [];
      }

      const textArrayInSituData = this.inSituData.values.map(
        (value, index) => ({
          value: value,
          date_time: this.inSituData.date_times[index].replace("T", " ")
        })
      );

      this.inSituDataToNoThresholdPlot = {
        type: "scatter",
        mode: "markers",
        marker: {
          color: this.singleColorInSituData,
          size: 7,
          symbol: "diamond"
        },
        x: this.inSituData.date_times,
        y: this.inSituData.values,
        customdata: ["sourceInSituData"],
        text: textArrayInSituData.map(
          item =>
            `${this.$t(
              "polygonStatistics.hovertemplate.inSituData"
            )}<br>${this.$t("polygonStatistics.hovertemplate.dateTime")}: ${
              item.date_time
            } UTC<br>${this.$t("polygonStatistics.hovertemplate.value")}: ${
              item.value
            } ${[this.unit]}<br>`
        ),
        hovertemplate: "<b>%{text}</b><br>" + "<extra></extra>"
      };
      return [this.inSituDataToNoThresholdPlot];
    },
    createPolygonStatisticsPlot() {
      if (
        !this.polygonStatistics ||
        !this.polygonStatistics.dateTime ||
        !this.polygonStatistics.values
      ) {
        return [];
      }
      let dateTimes = [...this.polygonStatistics.dateTime];
      let values = JSON.parse(JSON.stringify(this.polygonStatistics.values));

      // Remove null values from data
      let nullIndexes = new Set();

      Object.values(values).forEach(arr => {
        arr.forEach((val, index) => {
          if (val === null) {
            nullIndexes.add(index);
          }
        });
      });

      nullIndexes = Array.from(nullIndexes).sort((a, b) => b - a);
      nullIndexes.forEach(index => {
        dateTimes.splice(index, 1);
        Object.keys(values).forEach(key => {
          values[key].splice(index, 1);
        });
      });

      // after null values removed

      // Marker sizes
      const markerSizes = [5, 7, 9, 11, 13];

      // Assign marker size based on predefined ranges
      const markerSizesMap = {};

      // Create keys depending on the selected parameters
      const val = this.getKey(values, ["median", "mean"]);
      const lowerPercentile = this.getKey(values, ["p10", "p25"]);
      const higherPercentile = this.getKey(values, ["p75", "p90"]);
      const quality = this.getKey(values, ["valid_percent", "std"]);

      // Find min and max value for "std" or "valid_percent" - original/entire dataset
      const quality_or_std_values = JSON.parse(
        JSON.stringify(this.fetchedPolygonStatistics.data.B1)
      )[quality];

      const minValue = Math.min(...quality_or_std_values);
      const maxValue = Math.max(...quality_or_std_values);
      const range = maxValue - minValue;
      this.quality_or_std_ranges = [];

      // Calculate dynamic ranges based on the number of marker sizes
      for (let i = 0; i < markerSizes.length; i++) {
        this.quality_or_std_ranges.push(
          minValue + (i * range) / markerSizes.length
        );
      }
      this.quality_or_std_ranges.push(maxValue);

      // Reverse markerSizes if the quality is "std"
      const sizesToUse =
        quality === "std" ? markerSizes.slice().reverse() : markerSizes;

      // Normalize values and assign marker sizes
      for (let i = 0; i < quality_or_std_values.length; i++) {
        const value = quality_or_std_values[i];
        let sizeIndex = 0;
        for (let j = 0; j < this.quality_or_std_ranges.length - 1; j++) {
          if (value <= this.quality_or_std_ranges[j + 1]) {
            sizeIndex = j;
            break;
          }
        }
        markerSizesMap[value] = sizesToUse[sizeIndex];
      }

      const modifiedKey =
        quality === "valid_percent"
          ? this.$t("polygonStatistics.hovertemplate.validPixels")
          : this.$t("polygonStatistics.hovertemplate.stdFull");

      const unit = quality === "valid_percent" ? "[%]" : this.unit;
      const textArrayStationStats = values[quality].map((value, index) => ({
        stat_param: `${val}`,
        value: values[val][index],
        quality: value,
        date_time: dateTimes[index].replace("T", " ")
      }));

      // traces
      return [
        {
          name: `${lowerPercentile}`,
          x: dateTimes,
          y: values[lowerPercentile],
          marker: {
            color: "lightblue"
          }
        },
        {
          name: `${higherPercentile}`,
          x: dateTimes,
          y: values[higherPercentile],
          fill: "tonexty",
          marker: { color: "lightblue" }
        },
        {
          name: `${val}`,
          text: textArrayStationStats.map(
            item =>
              `${this.$t("polygonStatistics.hovertemplate.paramName")}: ${
                item.stat_param
              }<br>${this.$t("polygonStatistics.hovertemplate.dateTime")}: ${
                item.date_time
              } UTC<br>${this.$t("polygonStatistics.hovertemplate.value")}: ${
                item.value
              } ${[this.unit]}<br>${[modifiedKey]}: ${item.quality} ${[
                unit
              ]}<br>`
          ),
          x: dateTimes,
          y: values[val],
          mode: "markers",
          marker: {
            color: "#02A99F",
            size: values[quality].map(value => markerSizesMap[value])
          },
          hovertemplate: "<b>%{text}</b><br>" + "<extra></extra>"
        }
      ];
    },
    getKey(object, keys) {
      return keys.find(key => key in object);
    }
  },
  watch: {
    async save() {
      this.saveMarkedTimesteps();
    },
    editMode() {
      this.manageEditMode();
    },
    addedTimestep() {
      const newTimestep = this.addedTimestep[this.addedTimestep.length - 1];
      if (this.markedTimesteps.includes(newTimestep)) {
        const index = this.markedTimesteps.indexOf(newTimestep);
        this.markedTimesteps.splice(index, 1);
      } else [this.markedTimesteps.push(newTimestep)];
    },
    plotJsonFetch(value) {
      if (value != null) {
        let events = { statistics: false };
        let key = "statistics";
        if (Object.prototype.hasOwnProperty.call(events, key)) {
          this.createPlot();
        }
      }
    },
    timeLayer: {
      immediate: true,
      handler() {
        if (this.combinedPlotDataNOTHR.length) {
          this.updatePlot(this.combinedPlotDataNOTHR, this.plotLayout);
        }
      }
    },
    showPolygonStatistics: {
      immediate: true,
      handler() {
        if (this.combinedPlotDataNOTHR.length) {
          this.updatePlot(this.combinedPlotDataNOTHR, this.plotLayout);
        }
      }
    },
    plotData: {
      immediate: true,
      handler() {
        if (this.combinedPlotDataNOTHR.length) {
          this.updatePlot(this.combinedPlotDataNOTHR, this.plotLayout);
        }
      }
    },
    showInSituData: {
      immediate: true,
      handler() {
        if (this.combinedPlotDataNOTHR.length) {
          this.updatePlot(this.combinedPlotDataNOTHR, this.plotLayout);
          let lay = { xaxis: { autorange: true }, autosize: true };
          plotly.relayout(this.container, lay);
        }
      }
    },
    polygonStatistics: {
      immediate: true,
      handler() {
        if (this.combinedPlotDataNOTHR.length) {
          this.updatePlot(this.combinedPlotDataNOTHR, this.plotLayout);
        }
      }
    }
  },
  async created() {
    this.plotJsonFetch = this.jsonFetch;
    this.oldMarkedTimesteps = await this.getMarkedTimesteps(
      this.$route.params.regionId,
      this.productLayer,
      this.virtualStation.id
    );
    this.manageEditMode();
  }
};
</script>

<style scoped></style>
