import { mapState } from "vuex";

let polygonStatisticsMixins = {
  virtualStation: { name: "VirtualStation", geometry: null },
  methods: {
    async fetchPolygonStatistics(layer_id, geometry, parameters) {
      const body = {
        statistic: parameters.flat(1),
        geometry: geometry
      };

      try {
        const response = await this.$rastless.post(
          `v2/layers/${layer_id}/statistic?token=${this.accessToken}`,
          body
        );

        return { [layer_id]: response.data };
      } catch (error) {
        console.error(`Error making request for station statistics`, error);
      }
    }
  },
  computed: {
    ...mapState("management", ["accessToken"])
  }
};

export default polygonStatisticsMixins;
