<template>
  <div>
    <label class="font-weight-bold" style="font-size: 12px; display: block ">{{
      $t("dashboardDetails.statusLabelActual")
    }}</label>

    <label v-if="incomingDate" style="font-size: 14px; display: block">
      {{ timeFormat(selectedDate) }}
    </label>

    <label v-else-if="alert" style="font-size: 14px; display: block">
      {{
        productStatistics[item.id].current_status
          ? timeFormat(productStatistics[item.id].current_status.scene_date)
          : $t("dashboardDetails.noCurrentDate")
      }}
    </label>
    <label
      v-else-if="
        !productStatistics[item.id].statistics[
          productStatistics[item.id].statistics.length - 1
        ].scene_date
      "
      style="font-size: 14px; display: block"
    >
      {{ $t("dashboardDetails.noCurrentDate") }}
    </label>
    <label v-else style="font-size: 14px; display: block">
      {{
        timeFormat(
          productStatistics[item.id].statistics[
            productStatistics[item.id].statistics.length - 1
          ].scene_date
        )
      }}
    </label>
    <label
      v-if="incomingDate"
      :style="{
        color: getStatusColor(selectedStatus),
        fontSize: selectedStatus === 'no value' ? '14px' : '30px'
      }"
      style="font-family: 'Arial', sans-serif; display: inline-block"
      >{{ getSelectedDateValue(selectedDate).value }}</label
    >
    <label
      v-else-if="alert"
      :style="
        productStatistics[item.id].current_status
          ? {
              fontSize: '30px',
              fontFamily: 'Arial, sans-serif',
              display: 'inline-block',
              color: getStatusColor(
                productStatistics[item.id].current_status.status
              )
            }
          : {
              fontSize: '14px',
              display: 'block'
            }
      "
    >
      {{
        productStatistics[item.id].current_status
          ? productStatistics[item.id].current_status.value
          : $t("dashboardDetails.noValue")
      }}
    </label>

    <label
      v-else-if="
        !productStatistics[item.id].statistics[
          productStatistics[item.id].statistics.length - 1
        ]
      "
      style="font-size: 30px; font-family: 'Arial', sans-serif; display: inline-block"
      >{{ $t("dashboardDetails.noValue") }}</label
    >
    <label
      v-else
      style="font-size: 30px; font-family: 'Arial', sans-serif; display: inline-block"
      >{{
        productStatistics[item.id].statistics[
          productStatistics[item.id].statistics.length - 1
        ].value
      }}</label
    >
    <label
      v-if="incomingDate"
      :style="{
        color: getStatusColor(selectedStatus)
      }"
      style=" font-family: 'Arial', sans-serif; display: inline-block"
    >
      {{ item.productUnits }}</label
    >
    <label
      v-else-if="alert && productStatistics[item.id].current_status"
      :style="{
        color: getStatusColor(productStatistics[item.id].current_status.status)
      }"
      style=" font-family: 'Arial', sans-serif; display: inline-block"
    >
      {{ item.productUnits }}</label
    >
    <label
      v-else-if="!productStatistics[item.id].current_status"
      style="font-size: 14px; display: block"
    >
      {{ item.productUnits }}
    </label>
    <label
      v-else
      style=" font-family: 'Arial', sans-serif; display: inline-block"
    >
      {{ item.productUnits }}</label
    >
    <!-- PREVIOUS   -->
    <label class="font-weight-bold" style="font-size: 12px; display: block ">{{
      $t("dashboardDetails.statusLabelLast")
    }}</label>
    <label
      v-if="
        previousDateBeforeSelected === '0000-00-00 00:00:00' && incomingDate
      "
      style="font-size: 14px; display: block"
    >
      {{ $t("dashboardDetails.noPreviousDate") }}
    </label>
    <label v-else-if="incomingDate" style="font-size: 14px; display: block">
      {{ timeFormat(previousDateBeforeSelected) }}
    </label>
    <label
      v-else-if="alert && productStatistics[item.id].previous_status"
      style="font-size: 14px; display: block "
    >
      {{ timeFormat(productStatistics[item.id].previous_status.scene_date) }}
    </label>
    <label
      v-else-if="!productStatistics[item.id].previous_status"
      style="font-size: 14px; display: block"
    >
      {{ $t("dashboardDetails.noPreviousDate") }}
    </label>
    <label
      v-else-if="
        !productStatistics[item.id].statistics[
          productStatistics[item.id].statistics.length - 2
        ]
      "
      style="font-size: 14px; font-family: 'Arial', sans-serif; display: inline-block"
      >{{ $t("dashboardDetails.noPreviousDate") }}</label
    >
    <label v-else style="font-size: 14px; display: block ">
      {{
        timeFormat(
          productStatistics[item.id].statistics[
            productStatistics[item.id].statistics.length - 2
          ].scene_date
        )
      }}
    </label>
    <label
      v-if="incomingDate"
      :style="{
        color: getStatusColor(previousStatus),
        fontSize: previousStatus === 'no value' ? '14px' : '30px'
      }"
      style="font-family: 'Arial', sans-serif; display: inline-block"
      >{{ getPreviousSelectedDateValue(selectedDate).value }}</label
    >
    <label
      v-else-if="alert && productStatistics[item.id].previous_status"
      :style="{
        color: getStatusColor(productStatistics[item.id].previous_status.status)
      }"
      style="font-size: 30px; font-family: 'Arial', sans-serif; display: inline-block"
      >{{ productStatistics[item.id].previous_status.value }}</label
    >
    <label
      v-else-if="
        !productStatistics[item.id].previous_status &&
          productStatistics[item.id].current_status
      "
      style="font-size: 14px; display: block"
    >
      {{
        getPreviousSelectedDateValue(
          productStatistics[item.id].current_status.scene_date
        ).value
      }}
    </label>
    <label
      v-else-if="
        !productStatistics[item.id].previous_status &&
          !productStatistics[item.id].current_status
      "
      style="font-size: 14px; display: block"
    >
      {{ $t("dashboardDetails.noValue") }}
    </label>

    <label
      v-else-if="
        !productStatistics[item.id].statistics[
          productStatistics[item.id].statistics.length - 2
        ]
      "
      style="font-size: 14px; font-family: 'Arial', sans-serif; display: inline-block"
      >{{ $t("dashboardDetails.noValue") }}</label
    >
    <label
      v-else
      style="font-size: 30px; font-family: 'Arial', sans-serif; display: inline-block"
      >{{
        productStatistics[item.id].statistics[
          productStatistics[item.id].statistics.length - 2
        ].value
      }}</label
    >

    <label
      v-if="incomingDate"
      :style="{
        color: getStatusColor(previousStatus)
      }"
      style=" font-family: Arial, sans-serif; display: inline-block"
    >
      {{ item.productUnits }}</label
    >
    <label
      v-else-if="alert && productStatistics[item.id].previous_status"
      :style="{
        color: getStatusColor(productStatistics[item.id].previous_status.status)
      }"
      style=" font-family: 'Arial', sans-serif; display: inline-block"
    >
      {{ item.productUnits }}</label
    >
    <label
      v-else-if="!productStatistics[item.id].previous_status"
      style="font-size: 14px; display: block"
    >
      {{ item.productUnits }}
    </label>
    <label
      v-else-if="
        !productStatistics[item.id].statistics[
          productStatistics[item.id].statistics.length - 2
        ]
      "
      style=" font-size: 14px; font-family: 'Arial', sans-serif; display: block"
    >
      {{ item.productUnits }}</label
    >
    <label
      v-else
      style=" font-family: 'Arial', sans-serif; display: inline-block"
    >
      {{ item.productUnits }}</label
    >
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "StatusValues",
  props: {
    productStatistics: {
      type: Object
    },
    item: {
      type: Object
    },
    alert: {
      type: Boolean
    },
    selectedDate: String
  },
  data: () => ({
    statusFormatDate: "YYYY-MM-DD HH:mm",
    selectedStatus: null,
    selectedValue: null,
    incomingDate: false,
    previousDateBeforeSelected: null,
    previousValue: null,
    previousStatus: null
  }),
  methods: {
    getStatusColor(status) {
      if (status === "warning") {
        return "orange";
      } else if (status === "alert") {
        return "#f24726";
      } else if (status === "no value") {
        return "black";
      } else {
        return "#8fd14f";
      }
    },
    timeFormat: function(value) {
      return moment(value).format(this.statusFormatDate);
    },
    getSelectedDateValue(date) {
      const matchingStatistic = this.productStatistics[
        this.item.id
      ].statistics.find(stat => stat.scene_date === date);
      if (matchingStatistic) {
        const { value, status } = matchingStatistic;
        this.selectedStatus = status;
        return { status, value };
      } else {
        this.selectedStatus = "no value";
        this.selectedValue = this.$t("dashboardDetails.noValue");
        return { status: "no value", value: this.selectedValue };
      }
    },
    getPreviousSelectedDateValue(date) {
      const statistics = this.productStatistics[this.item.id].statistics;
      const index = statistics.findIndex(stat => stat.scene_date === date);
      if (index > 0) {
        const previousStatistic = statistics[index - 1];
        this.previousDateBeforeSelected = previousStatistic.scene_date;
        const { value, status } = previousStatistic;
        this.previousStatus = status;
        return { status, value };
      } else {
        // nearest previous date before the selected date
        const sortedDates = statistics.map(stat => stat.scene_date).sort();
        const nearestPreviousDate = sortedDates
          .reverse()
          .find(previousDate => previousDate < date);

        if (nearestPreviousDate) {
          const nearestPreviousStatistic = statistics.find(
            stat => stat.scene_date === nearestPreviousDate
          );

          this.previousDateBeforeSelected = nearestPreviousStatistic.scene_date;
          const { value, status } = nearestPreviousStatistic;
          this.previousStatus = status;
          return { status, value };
        } else {
          this.previousDateBeforeSelected = "0000-00-00 00:00:00";
          this.previousStatus = "no value";
          this.previousValue = this.$t("dashboardDetails.noValue");
          return { status: "no value", value: this.previousValue };
        }
      }
    }
  },
  watch: {
    selectedDate() {
      this.incomingDate = true;
    }
  }
};
</script>

<style scoped></style>
