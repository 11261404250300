var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('label',{staticClass:"font-weight-bold",staticStyle:{"font-size":"12px","display":"block"}},[_vm._v(_vm._s(_vm.$t("dashboardDetails.statusLabelActual")))]),(_vm.incomingDate)?_c('label',{staticStyle:{"font-size":"14px","display":"block"}},[_vm._v(" "+_vm._s(_vm.timeFormat(_vm.selectedDate))+" ")]):(_vm.alert)?_c('label',{staticStyle:{"font-size":"14px","display":"block"}},[_vm._v(" "+_vm._s(_vm.productStatistics[_vm.item.id].current_status ? _vm.timeFormat(_vm.productStatistics[_vm.item.id].current_status.scene_date) : _vm.$t("dashboardDetails.noCurrentDate"))+" ")]):(
      !_vm.productStatistics[_vm.item.id].statistics[
        _vm.productStatistics[_vm.item.id].statistics.length - 1
      ].scene_date
    )?_c('label',{staticStyle:{"font-size":"14px","display":"block"}},[_vm._v(" "+_vm._s(_vm.$t("dashboardDetails.noCurrentDate"))+" ")]):_c('label',{staticStyle:{"font-size":"14px","display":"block"}},[_vm._v(" "+_vm._s(_vm.timeFormat( _vm.productStatistics[_vm.item.id].statistics[ _vm.productStatistics[_vm.item.id].statistics.length - 1 ].scene_date ))+" ")]),(_vm.incomingDate)?_c('label',{staticStyle:{"font-family":"'Arial', sans-serif","display":"inline-block"},style:({
      color: _vm.getStatusColor(_vm.selectedStatus),
      fontSize: _vm.selectedStatus === 'no value' ? '14px' : '30px'
    })},[_vm._v(_vm._s(_vm.getSelectedDateValue(_vm.selectedDate).value))]):(_vm.alert)?_c('label',{style:(_vm.productStatistics[_vm.item.id].current_status
        ? {
            fontSize: '30px',
            fontFamily: 'Arial, sans-serif',
            display: 'inline-block',
            color: _vm.getStatusColor(
              _vm.productStatistics[_vm.item.id].current_status.status
            )
          }
        : {
            fontSize: '14px',
            display: 'block'
          })},[_vm._v(" "+_vm._s(_vm.productStatistics[_vm.item.id].current_status ? _vm.productStatistics[_vm.item.id].current_status.value : _vm.$t("dashboardDetails.noValue"))+" ")]):(
      !_vm.productStatistics[_vm.item.id].statistics[
        _vm.productStatistics[_vm.item.id].statistics.length - 1
      ]
    )?_c('label',{staticStyle:{"font-size":"30px","font-family":"'Arial', sans-serif","display":"inline-block"}},[_vm._v(_vm._s(_vm.$t("dashboardDetails.noValue")))]):_c('label',{staticStyle:{"font-size":"30px","font-family":"'Arial', sans-serif","display":"inline-block"}},[_vm._v(_vm._s(_vm.productStatistics[_vm.item.id].statistics[ _vm.productStatistics[_vm.item.id].statistics.length - 1 ].value))]),(_vm.incomingDate)?_c('label',{staticStyle:{"font-family":"'Arial', sans-serif","display":"inline-block"},style:({
      color: _vm.getStatusColor(_vm.selectedStatus)
    })},[_vm._v(" "+_vm._s(_vm.item.productUnits))]):(_vm.alert && _vm.productStatistics[_vm.item.id].current_status)?_c('label',{staticStyle:{"font-family":"'Arial', sans-serif","display":"inline-block"},style:({
      color: _vm.getStatusColor(_vm.productStatistics[_vm.item.id].current_status.status)
    })},[_vm._v(" "+_vm._s(_vm.item.productUnits))]):(!_vm.productStatistics[_vm.item.id].current_status)?_c('label',{staticStyle:{"font-size":"14px","display":"block"}},[_vm._v(" "+_vm._s(_vm.item.productUnits)+" ")]):_c('label',{staticStyle:{"font-family":"'Arial', sans-serif","display":"inline-block"}},[_vm._v(" "+_vm._s(_vm.item.productUnits))]),_c('label',{staticClass:"font-weight-bold",staticStyle:{"font-size":"12px","display":"block"}},[_vm._v(_vm._s(_vm.$t("dashboardDetails.statusLabelLast")))]),(
      _vm.previousDateBeforeSelected === '0000-00-00 00:00:00' && _vm.incomingDate
    )?_c('label',{staticStyle:{"font-size":"14px","display":"block"}},[_vm._v(" "+_vm._s(_vm.$t("dashboardDetails.noPreviousDate"))+" ")]):(_vm.incomingDate)?_c('label',{staticStyle:{"font-size":"14px","display":"block"}},[_vm._v(" "+_vm._s(_vm.timeFormat(_vm.previousDateBeforeSelected))+" ")]):(_vm.alert && _vm.productStatistics[_vm.item.id].previous_status)?_c('label',{staticStyle:{"font-size":"14px","display":"block"}},[_vm._v(" "+_vm._s(_vm.timeFormat(_vm.productStatistics[_vm.item.id].previous_status.scene_date))+" ")]):(!_vm.productStatistics[_vm.item.id].previous_status)?_c('label',{staticStyle:{"font-size":"14px","display":"block"}},[_vm._v(" "+_vm._s(_vm.$t("dashboardDetails.noPreviousDate"))+" ")]):(
      !_vm.productStatistics[_vm.item.id].statistics[
        _vm.productStatistics[_vm.item.id].statistics.length - 2
      ]
    )?_c('label',{staticStyle:{"font-size":"14px","font-family":"'Arial', sans-serif","display":"inline-block"}},[_vm._v(_vm._s(_vm.$t("dashboardDetails.noPreviousDate")))]):_c('label',{staticStyle:{"font-size":"14px","display":"block"}},[_vm._v(" "+_vm._s(_vm.timeFormat( _vm.productStatistics[_vm.item.id].statistics[ _vm.productStatistics[_vm.item.id].statistics.length - 2 ].scene_date ))+" ")]),(_vm.incomingDate)?_c('label',{staticStyle:{"font-family":"'Arial', sans-serif","display":"inline-block"},style:({
      color: _vm.getStatusColor(_vm.previousStatus),
      fontSize: _vm.previousStatus === 'no value' ? '14px' : '30px'
    })},[_vm._v(_vm._s(_vm.getPreviousSelectedDateValue(_vm.selectedDate).value))]):(_vm.alert && _vm.productStatistics[_vm.item.id].previous_status)?_c('label',{staticStyle:{"font-size":"30px","font-family":"'Arial', sans-serif","display":"inline-block"},style:({
      color: _vm.getStatusColor(_vm.productStatistics[_vm.item.id].previous_status.status)
    })},[_vm._v(_vm._s(_vm.productStatistics[_vm.item.id].previous_status.value))]):(
      !_vm.productStatistics[_vm.item.id].previous_status &&
        _vm.productStatistics[_vm.item.id].current_status
    )?_c('label',{staticStyle:{"font-size":"14px","display":"block"}},[_vm._v(" "+_vm._s(_vm.getPreviousSelectedDateValue( _vm.productStatistics[_vm.item.id].current_status.scene_date ).value)+" ")]):(
      !_vm.productStatistics[_vm.item.id].previous_status &&
        !_vm.productStatistics[_vm.item.id].current_status
    )?_c('label',{staticStyle:{"font-size":"14px","display":"block"}},[_vm._v(" "+_vm._s(_vm.$t("dashboardDetails.noValue"))+" ")]):(
      !_vm.productStatistics[_vm.item.id].statistics[
        _vm.productStatistics[_vm.item.id].statistics.length - 2
      ]
    )?_c('label',{staticStyle:{"font-size":"14px","font-family":"'Arial', sans-serif","display":"inline-block"}},[_vm._v(_vm._s(_vm.$t("dashboardDetails.noValue")))]):_c('label',{staticStyle:{"font-size":"30px","font-family":"'Arial', sans-serif","display":"inline-block"}},[_vm._v(_vm._s(_vm.productStatistics[_vm.item.id].statistics[ _vm.productStatistics[_vm.item.id].statistics.length - 2 ].value))]),(_vm.incomingDate)?_c('label',{staticStyle:{"font-family":"Arial, sans-serif","display":"inline-block"},style:({
      color: _vm.getStatusColor(_vm.previousStatus)
    })},[_vm._v(" "+_vm._s(_vm.item.productUnits))]):(_vm.alert && _vm.productStatistics[_vm.item.id].previous_status)?_c('label',{staticStyle:{"font-family":"'Arial', sans-serif","display":"inline-block"},style:({
      color: _vm.getStatusColor(_vm.productStatistics[_vm.item.id].previous_status.status)
    })},[_vm._v(" "+_vm._s(_vm.item.productUnits))]):(!_vm.productStatistics[_vm.item.id].previous_status)?_c('label',{staticStyle:{"font-size":"14px","display":"block"}},[_vm._v(" "+_vm._s(_vm.item.productUnits)+" ")]):(
      !_vm.productStatistics[_vm.item.id].statistics[
        _vm.productStatistics[_vm.item.id].statistics.length - 2
      ]
    )?_c('label',{staticStyle:{"font-size":"14px","font-family":"'Arial', sans-serif","display":"block"}},[_vm._v(" "+_vm._s(_vm.item.productUnits))]):_c('label',{staticStyle:{"font-family":"'Arial', sans-serif","display":"inline-block"}},[_vm._v(" "+_vm._s(_vm.item.productUnits))])])
}
var staticRenderFns = []

export { render, staticRenderFns }